let Data = [
  {
    id: 1,
    title: "Flex & Grid",
    routeAddress: [
      {
        id: 2,
        route: "/flex",
        name: "Flex",
      },
      {
        id: 3,
        route: "/grid",
        name: "Grid",
      },
    ],
  },
];

export default Data;
